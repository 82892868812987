import "./index.less"
import ReactPlayer from 'react-player'
import { ChangeEvent, FC, useEffect, useState } from "react"
import defaultPhoto from "../../../../images/default-photo.png"
import { Spin } from "antd"
import ToolTip from "../ToolTip"

const getFileDimensions = (file: File): Promise<Record<"width" | "height", number | null>> => {
  return new Promise(resolve => {
    const dimensions: Record<"width" | "height", number | null> = {
      width: null,
      height: null,
    }

    if (file?.type?.includes("video")) {
      const video = document.createElement("video")
      const videoURL = URL.createObjectURL(file)

      video.src = videoURL

      video.addEventListener("loadedmetadata", () => {
        dimensions.width = video.videoWidth
        dimensions.height = video.videoHeight

        URL.revokeObjectURL(videoURL)
        resolve(dimensions)
      })
    } else {
      const img = new Image()
      const imageURL = URL.createObjectURL(file)

      img.src = imageURL

      img.onload = () => {
        dimensions.width = img.width
        dimensions.height = img.height

        URL.revokeObjectURL(imageURL)
        resolve(dimensions)
      }
    }
  })
}

const ImageLoader: FC<ImageLoaderInterface> = ({
  type,
  onLoad,
  filters,
  setFilters,
  fetchedVideoURL,
  fetchedImageURL,
  itemIndex = null,
  acceptVideo = false,
  canBeRemoved = false,
  maxImageSize = 5 * 1_000_000,
  maxVideoSize = 15 * 1_000_000,
  isChangesDisabled = false,
  formatsDescription = true,
}) => {
  const [preview, setPreview] = useState<string>("")
  const [videoVisibility, setVideoVisibility] = useState<boolean>(false)

  const handleOnLoad = () => {
    if (onLoad) {
      onLoad(itemIndex!)
    }
  }

  const addImage = (formData: FormData, file: File) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      if (typeof e.target?.result === "string") {
        setPreview(e.target.result)
      }
    }
    reader.readAsDataURL(file)
  }

  const handleDeleteImage = () => {
    setPreview("")
    setFilters({
      ...filters,
      currentImage: "",
      [type]: null,
    })
  }

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const input = e.target

    if (!input.files || !input.files[0]) {
      return
    }

      const file = input.files[0]

      if (file.size > (file?.type?.includes("video") ? maxVideoSize : maxImageSize)) {
      // TODO: show error about file size
        return
      }

      setVideoVisibility(false)

      const formData = new FormData()
      formData.append("files", file)

      addImage(formData, file)

    const fileDimensions = await getFileDimensions(file)

      if (itemIndex == null) {
        setFilters({
          ...filters,
        ...fileDimensions,
          [type]: formData,
        })
      } else {
        setFilters({
          ...filters,
        ...fileDimensions,
          items: [
            ...(filters!.items as any).map((item: any, i: number) => ({
              ...item,
              ...(i == itemIndex ? { [type]: formData } : {}),
            })),
          ],
        })
      }
  }

  const noContent = !preview && !fetchedImageURL && !fetchedVideoURL;
  const isImage = (fetchedImageURL || preview?.includes("data:image")) && !(preview && preview?.includes("data:video"));
  const isVideo = (fetchedVideoURL || preview?.includes("data:video")) && !(preview && preview?.includes("data:image"));

  useEffect(() => {
    setPreview(filters?.youtubeLink ? "" : (fetchedImageURL || fetchedVideoURL || ""))
  }, [fetchedImageURL, fetchedVideoURL, filters?.youtubeLink])

  return (
    <div className={"image-upload-container"}>
      {!preview && !filters?.youtubeLink && (
        <div className={"default-text-placeholder"}>
          {`Click to this area to upload ${acceptVideo ? "file" : "image"}`}
        </div>
      )}

      <div className={"image-upload"}>

        <div className={isVideo ? "video-preview" : "image-preview"}>
          {isVideo && !videoVisibility && <div className={"spin-wrapper"} children={<Spin/>} />}
          {noContent && <img src={defaultPhoto} onLoad={handleOnLoad} />}
          {isImage && <img src={preview} className='image' />}
          {isVideo && (
              <div className={"video"} style={videoVisibility ? {opacity: 1} : {}} >
                <ReactPlayer
                  volume={0}
                  url={preview}
                  controls={true}
                  playing={false}
                  onReady={() => setVideoVisibility(true)}
                  onError={() => setVideoVisibility(false)}
                />
              </div>
            )
          }
          {filters?.youtubeLink && <div children={"YouTube link has been added"} className={"additional-description"}/>}
          <input
            type={"file"}
            className={"imageUpload"}
            id={`imageUpload-${itemIndex}-${type}`}
            key={`imageUpload-${itemIndex}-${type}`}
            accept={`.png, .jpg, .jpeg${acceptVideo ? ", .mp4, .mov" : ""}`}
            onChange={handleImageChange}
          />

          {!isChangesDisabled && (
            <div className={`options ${isVideo ? "video-options" : ""}`}>
              <ToolTip
                placement={isVideo ? "bottomRight" : "top"}
                size={"fit-content-wide"}
                title={
                  <div className={"default-extra-text-placeholder"}>
                    <span>Max size: </span>
                    {` ${(maxImageSize / 1_000_000).toFixed(2)} mb${
                      acceptVideo ? ` (image) and ${(maxVideoSize / 1_000_000).toFixed(0)}mb (video)` : ""
                    }`}
                    <br/>
                    <span>Supported formats: </span>
                    {` .png, .jpg, .jpeg${acceptVideo ? ", .mp4, .mov" : ""}`}
                  </div>
                }
                disabled={!formatsDescription}
              >
                <label className={`edit ${isVideo ? "video-options" : ""}`} htmlFor={`imageUpload-${itemIndex}-${type}`}>
                  {`Add new ${acceptVideo ? "file" : "image"}`}
                </label>
              </ToolTip>

              {canBeRemoved && (
                <label className={"delete"} onClick={handleDeleteImage}>
                  Delete image
                </label>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageLoader
