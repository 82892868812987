import { Form as AntForm, Input, Select, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useAuth } from "../../../../../../../../../components/auth"
import { useCreateLinkMutation, useGetLinkAmountLazyQuery, useUpdateLinkMutation } from "../../../../../../../../../graphql"
import { SaveIcon, SearchIcon, TrashIcon } from "../../../../../../../../../icons"
import { areAllKeysPopulated, areObjectsEqual, checkFormNotDone, truncate } from "../../../../../../../../../utils"
import { TextEditor } from "../../../../../../../components/TextEditor"
import "./index.less"
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""

const Form: FC<LinkManageBlockLeftSideFormInterface> = ({
  link,
  types,
  filters,
  showModal,
  setFilters,
  initialFilters,
  selectedLinkId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const [getLinks] = useGetLinkAmountLazyQuery()

  const { userId, permissions } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Assets")

  const [createLink] = useCreateLinkMutation()
  const [updateLink] = useUpdateLinkMutation()

  const [errorMessage, setErrorMessage] = useState<string>("")

  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleValuesChange = (value: any, type: string) => {
    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const handleFinish = async () => {
    const { title, description, type, urlLink, image, width, height } = filters as any

    try {
      setFinishLoading(true)

      let imageId

      if (image) {
        if (link?.attributes?.image?.data?.id) {
          try {
            await fetch(url + `/api/upload/files/${link?.attributes?.image?.data?.id}`, {
              method: "DELETE",
              body: JSON.stringify({
                accessToken: localStorage.getItem("accessToken"),
              }),
            })
          } catch (error) {
            console.log(error)
          }
        }
        const uploadResponse = await fetch(url + "/api/upload", {
          method: "POST",
          body: image,
        })
        const uploadData = await uploadResponse.json()
        if (!uploadData?.[0]?.id) {
          throw new Error("failed to upload image")
        }
        imageId = uploadData?.[0]?.id
      }

      const more_section_type_id = types.find(item => item.value == type)!.key
      const linksWithType = await getLinks({ variables: { filter: { more_section_type: { id: { eq: more_section_type_id } } } } })
      const totalLinks = linksWithType.data?.appNewsPosts?.meta.pagination.total

      const data: AppNewsPostInput = {
        title,
        link: urlLink,
        image: imageId,
        author: userId,
        body: description,
        width,
        height,
        type: "links",
        ...(selectedLinkId ? {} : { sequence: +totalLinks! + 1 }),
        ...(selectedLinkId ? {} : { more_section_type: more_section_type_id })
      }

      let newLink
      let updatedLink

      if (!selectedLinkId) {
        newLink = await createLink({
          variables: {
            data,
          },
        })
      } else {
        updatedLink = await updateLink({
          variables: {
            id: String(selectedLinkId),
            data,
          },
        })
      }

      if (newLink?.data?.createAppNewsPost?.data?.id || updatedLink?.data?.updateAppNewsPost?.data?.id) {
        let newFilters = JSON.parse(JSON.stringify(filters))
        newFilters.currentImage =
          newLink?.data?.createAppNewsPost?.data?.attributes?.image?.data?.attributes?.url ||
          updatedLink?.data?.updateAppNewsPost?.data?.attributes?.image?.data?.attributes?.url ||
          ""
        newFilters.image = null

        setInitialFilters(newFilters)
        setFilters(newFilters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (type: string, name: string, options: SelectItems) => {
    const value = filters?.[type] || ""

    return (
      <AntForm.Item name={name} label={name} required={true}>
        <div className={"input-wrapper"}>
          <Select
            showSearch
            disabled={type == "type" && link}
            value={value}
            options={options}
            optionFilterProp='children'
            filterOption={filteredOption as any}
            onChange={data => handleValuesChange(data, type)}
            defaultActiveFirstOption={true}
            suffixIcon={<SearchIcon />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string) => (
    <AntForm.Item label={label} required={true}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(
            type == "title" ?
              e.target.value.substring(0, 45) :
              e.target.value, type
          )}
          value={(filters?.[type] as string) || ""}
        />
        {type == "title" && <div className={"limit-identifier"}>{filters?.title?.length || 0} / 45</div>}
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["currentImage"]
      if (filters.currentImage) notRequiredKeys.push("image")

      const isDifferentValues = !areObjectsEqual(filters, initialFilters)
      const isValuesValid = areAllKeysPopulated(filters, notRequiredKeys)
      checkFormNotDone({ isDifferentRootValues: isDifferentValues })
      setIsFormReady(isDifferentValues && isValuesValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper link"}>
          <div className={"block"}>
            <div className={"title"}>
              <div>TITLE</div>
              <div>TYPE</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createInputElement("title", "Title")}
              {createSelectElement("type", "Type", types!)}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>DESCRIPTION</div>
            <div className={"inputs-wrapper wide-input"}>
              <TextEditor
                limit={240}
                handleMarkdown={handleValuesChange}
                title={"Description"}
                type={"description"}
                fetchedMarkdown={link?.attributes?.body as string | undefined}
              />
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>LINKS</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("urlLink", "URL Link")}</div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm>

      {blockPermissions?.edit && (
        <button
          type={"submit"}
          onClick={handleFinish}
          className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
          disabled={!isFormReady || isChangesDisabled}
        >
          Save
          {finishLoading ? <Spin /> : <SaveIcon />}
        </button>
      )}

      {selectedLinkId && blockPermissions?.delete ? (
        <button onClick={() => showModal()} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      ) : null}
    </>
  )
}

export default Form
